blockquote {
    @include size-L;
    padding: ($unit * 2) 0 ($unit * 2) ($unit * 6);
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: $color-primary;
        background-color: var(--color-accent1);
        width: 4px;
        clip-path: polygon(0 0, 0 calc(50% - 2rem), 100% calc(50% - 2rem), 100% calc(50% + 2rem), 0 calc(50% + 2rem), 0 100%, 100% 100%, 100% 0);
    }

    &:after {
        content:"";
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.222 34c3.422 0 6.222-2.848 6.222-6.329 0-3.48-2.8-6.329-6.222-6.329-.87 0-1.68.19-2.489.506 1.058-2.405 2.676-4.43 4.605-5.443.622-.316.809-1.076.498-1.709-.25-.443-.623-.633-1.12-.633-.187 0-.436.064-.623.127C12.987 16.468 10 22.102 10 27.671c0 3.481 2.8 6.33 6.222 6.33zm15.556 0C35.2 34 38 31.152 38 27.671c0-3.48-2.8-6.329-6.222-6.329-.871 0-1.68.19-2.49.506 1.059-2.405 2.676-4.43 4.605-5.443.623-.316.81-1.076.498-1.709-.249-.443-.622-.633-1.12-.633-.187 0-.435.064-.622.127-4.107 2.278-7.094 7.912-7.094 13.481 0 3.481 2.8 6.33 6.223 6.33z' fill='#{url-friendly-colour($color-primary)}'/%3E%3C/svg%3E");
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: calc(50% - 1.5rem);
        left: -1.5rem;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}