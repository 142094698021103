* {
    box-sizing: border-box;
}

.cf:before,
.cf:after,
.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after,
.clearfix:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf,
.clearfix {
    *zoom: 1;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    height: 100%;
    width: 100%;

    @include breakpoint (XS) {
        height: 100%;
    }
}

body {
    background-color: $color-white-off;
    color: $color-body;
    //@extend %nav-transition;

    &.menu-open {
        overflow: hidden;

        &:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-back-60;
            z-index: 22;
            @include transition(.3s $easeInOut all);
        }
    }

    &.overflow-hidden {
        overflow: hidden;
    }
}

body::-webkit-scrollbar {
    width: 0.75em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    background-color: #EFF1EF;
}

body::-webkit-scrollbar-thumb {
    background-color: $color-green-25;
}

::-moz-selection {
	background-color: $color-green;
    color: $color-white;
}

::selection {
	background-color: $color-green-25;
    color: inherit;
}

.date-input {
    position: relative;
    background: #fff;
    margin-bottom: 1em;

    input {
        background: transparent;
        position: relative;
        z-index: 2;
        margin-bottom: 0;
    }
    svg {
        width: 2em;
        height: 2em;
        position: absolute;
        content: "";
        right: 1em;
        top: 0.5em;
        z-index: 1;

        path {
            stroke: $color-primary;
        }
    }
}

div.wpcf7 {
    width: 100%;
}

// this is here so it loads above the fold and doesn't flash up
.gallery-modal,
.room-modal,
.event-modal,
.custom-modal,
.event-modal-single {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    bottom: -200%;
    left: 0%;
    width: 100%;
    height: 100%;
    @extend %transition;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 100;
        bottom: 0;

        @include breakpoint(S) {
            display: block;
        }
    }
}

.single-events .event-modal-single.active{
    position: unset;
}

/* IE AOS fix */
@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop,
    [data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{
        opacity: 1;
    }
}

.toggle-content-target {
    display: none;
}

.content-truncated {
    max-height: 8rem;
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;
    @extend %transition;

    &:after {
        @extend %transition;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3rem;
        width: 100%;
        z-index: 3;
        background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
}

.content-truncated-block {
    &.toggled {
        .content-truncated {
            max-height: 20rem;

            &:after {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.error.notice {
    position: fixed;
    width: 17em;
    right: 5em;
    bottom: 2em;
    z-index: 20;
    color: white;
    font-size: 16px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid $color-body;
    ::before {
        content: "Caching is disabled";
        display: block;
        font-weight: 800;
        color: $color-body;
    }
    p {
        margin-bottom: 0em;
        padding: 2em;
    }
}

// Remove google recapcha v3 styling

.grecaptcha-badge {
    visibility: hidden !important;
}

// Import base style partials
@import "blockquotes";
@import "hr";
@import "links";
@import "lists";
@import "tables";
