hr {
    border: 0;
    margin-top: 1.5rem;
    margin-right: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;
    width: 100%;
    border-bottom: 2px solid $color-green;

    &.centered {
        margin-left: auto;
        margin-right: auto;
    }
}

h1,
h2,
h3 {
    + hr {
        margin-top: -1.25rem;
        width: 7.5rem;
    }
}

h4,
h5,
h6 {
    + hr {
        margin-top: -1.5rem;
        width: 2.5rem;
    }
}
