@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/placeholders";
@import "base/colors/_colors-core";
@import "base/_base-styles-core";
@import "base/typography/_typography-core";

.exitcapture-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    background-color: #18332eed;
    &.has-custom-theme {
        .button,
        .exitcapture-close svg{
            color: var(--color-body);
            &:hover{
                color: var(--hover-color) !important;
            }
        }
        .modal-content {
            color: var(--color-body) !important;
            background: var(--color-background) !important;
            .countdown .countdown-item{
                color: var(--button-color) !important;
                background: var(--button-background) !important;
            }
          
        }
    }
}

.no-margin{
    margin: unset!important;
}

.exitcapture-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem;

    .exitcapture-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .modal {
        max-width: 40rem;
        width: 100%;
        position: relative;
        z-index: 101;
       background-color: $color-white;

        .modal-content {
            padding: 5rem 3.5rem 4rem 3.5rem;

            @include breakpoint(XS) {
                padding: 5rem 1.5rem 2rem 1.5rem;
            }
        }
        .exitcapture-close {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }
        &.has-image {
            max-width: 56rem;

            .modal-img,
            .modal-content {
                width: 50%;
                img{
                    height: 100%;
                }
                @include breakpoint(S) {
                    width: 100%;
                }
            }
            .modal-img {
                position: relative;

                @include breakpoint(S) {
                    display: none;
                }
            }
            .modal-content{
                .button{
                    justify-content: center;
                }
            }
            .countdown{
                .countdown-item{
                    color: white;
                }
            }
        }
    }
}

.countdown {
    .countdown-item {
        
        background-color: $color-body;
       
        color: $color-white;
        border-radius: 0.5rem;
        font-weight: 700;
        padding: 0.5rem 0.75rem;
        @include size-L;
        margin: 0 0.125rem;

        @include breakpoint(XS) {
            text-transform: uppercase;
            letter-spacing: 0.125em;
            @include size-S;
            padding: 0.25rem 0.65rem;
        }
    }
}

.slide-callout {
    display: none;
    position: fixed;
    right: 0;
    bottom: 8rem;
    z-index: 10;
    width: 16rem;
    background-color: $color-white;
    border: 2px solid;
  
    border-color: $color-green;
    border-right: 0;
    @extend %transition;
    transition-delay: 0.3s;

    &.has-custom-theme {
        color: var(--color-body) !important;
        background: var(--color-background) !important;
        &:hover{
            h3,
            .slide-callout-close svg{
                color: var(--hover-color) !important;
            }
        }
        .slide-callout-inner a:hover{
            color: var(--hover-color) !important;
        }
      
    }

    .slide-callout-title {
        position: relative;

        h3 {
            padding: 1rem 0.5rem 1rem 1.25rem;
        }
        .slide-callout-close {
            border-left: 1px solid;
         border-color: $color-back;
            padding: 0 1rem;
            pointer-events: none;

            svg {
                @extend %transition;
            }
        }
        &:after {
            // blanker over the top of the close button to stop clicks until it's open
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 4rem;
            z-index: 1;
            transition-delay: 0s;
        }
    }
    .slide-callout-inner {
        max-height: 0;
        @extend %transition;
        padding: 0 1.25rem;
        overflow: hidden;
        opacity: 0;
        border-top: 1px solid;
     border-color: $color-back;
        transition-delay: 0s;

        p {
            padding: 1.5rem 0;
        }
        li {
            @include size-S;
            padding: 0.75rem 0;
            border-bottom: 1px solid;
            border-color: $color-back;
            svg {
            color: $color-accent;
                margin-right: 0.75rem;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                border: 0;
                padding-bottom: 1.5rem;
            }
        }
    }
    .button {
        width: calc(100% + 2.5rem);
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }
    &:hover {
        width: 20rem;
        transition-delay: 0s;

        h3,
        .slide-callout-close svg {
        color: $color-accent;
        }
        .slide-callout-close svg {
            display: block;
            transform: rotate(45deg);
        }
        .slide-callout-inner {
            max-height: 20rem;
            opacity: 1;
            transition-delay: 0.3s;
        }
        .slide-callout-close {
            pointer-events: auto;
        }
        .slide-callout-title:after {
            transform: translateX(150%);
            transition-delay: 0.5s;
        }
    }
}

.highlight-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding-left: 2rem;
    display: none;
    background-color: $color-green;
    color:$color-white;

    &.has-custom-theme {
        background-color: var(--color-background);
        color: var(--color-body);
        .highlight-bar-close:hover{
            background-color: var(--hover-color);
        }
        .button{
            background-color: var(--button-color);
            color: var(--button-background);
        }
    }

    @include breakpoint(XS) {
        padding: 1rem;
        .items-center{
            display: flex;
            flex-direction: column;
            .highlight-bar-inner{
                max-width: unset;
                text-align: center;
            }
        }

        .countdown {
            .countdown-item:first-child {
                margin-left: 0;
            }
        }
    }
    .highlight-bar-inner {
        padding: 0.5rem 0.5rem 0.5rem 0;

        @include breakpoint(XS) {
            padding: 0.75rem 0 0.75rem 1.5rem;
            max-width: 60%;
        }
    }
    .countdown {
        margin-left: 2rem;

        @include breakpoint(XS) {
            margin: 0.5rem 0 0 0;
        }
    }
    .button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: white;
        flex-basis: auto;
        flex-grow: 0;
        padding: 1.5rem;
    }
    .highlight-bar-close {
        width: 3.5rem;
        align-self: stretch;
        color: $color-white;
        padding: 0.5rem;

        @include breakpoint(XS) {
            position: absolute;
            right: 0.5rem;
            top: -1.5rem;
            border-radius: 100%;
            border: 1px solid;
            border-color: $color-accent;
            color: $color-accent;
            width: 2.25rem;
            height: 2.25rem;
            background-color: $color-white;
            z-index: 1;

            svg {
                width: 18px;
                height: 18px;
            }
        }
        &:hover {
            background-color: $color-accent;
        }
    }
}