a {
    position: relative;
    color: $color-active;
    @extend %transition;

    &:hover {
        color: mix($color-active, white, 50%);
    }

    &.underlined {
        display: inline-block;

        &:before {
            position: absolute;
            content: "";
            height: 1px;
            background: $color-body;
            bottom: 0;
            width: 100%;
        }
        &:after {
            position: absolute;
            content: "";
            height: 1px;
            background: $color-primary;
            bottom: 0;
            left: 50%;
            width: 0;
            transform: translateX(-50%);
            @extend %nav-transition;
        }
        &:hover {
            color: $color-primary;

            &:after {
                width: 100%;
            }
        }
        &.small {
            font-size: 0.875em;
        }
        &.white {
            color: #fff;
            border-bottom: 1px solid #fff;

            &:hover {
                color: $color-primary;
            }
            &:after {
                background: $color-primary;
            }
        }
    }
}
