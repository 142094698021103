table {
    width: 100%;
    text-align: left;

    th {
        color: $color-body;
        color: var(--color-body);
        font-weight: 700;
        padding: $unit * 2;
        border-bottom: 1px solid $color-primary;
        border-color: var(--color-accent2);
    }
    tr {

        &:hover {

            td {
                @extend %button-transition;

                color: $color-white;
                color: var(--color-reverse);

                background-color: $color-primary;
                background-color: var(--color-accent1);
            }

            button, .button {
                color: $color-white;
                color: var(--color-reverse);

                padding-left: $unit;

                &:after {
                    filter: brightness(10);
                }
            }
        }
        td {
            padding: $unit ($unit * 2);

            > * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    tfoot {
        td {
            padding: 1.5em 1em;
            font-weight: 700;
            color: #fff;
            border: 2px solid $color-body;
            background: $color-primary;
        }
    }
    @include breakpoint(XS) {
        overflow-x: scroll;
        display: block;
    }

    .button, button {
        color: rgba($color-body, 0);
        width: 100%;
        height: 100%;
        padding-left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:before {
            display: none;
        }

        &:after {
            background-color: transparent;
            position: absolute;
            transform: scaleX(1);
            opacity: 1;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-right%3C/title%3E%3Cpolyline points='8 5 16 12 8 19' fill='none' stroke='%234A4F54' stroke-linejoin='round'/%3E%3C/svg%3E");
            width: $unit * 3;
            height: $unit * 3;
            left: calc(100% - #{$unit * 3});
            top: calc(50% - #{$unit * 1.5});

            @extend %button-transition;

        }
    }
}
