h1,
.h1 {
    @include size-3XL;
    font-weight: 400;
    font-family: $font-stack-primary;
}

h2,
.h2 {
    @include size-2XL;
    font-weight: 400;
    font-family:$font-stack-primary;
}

h3,
.h3 {
    @include size-XL;
}

h4,
.h4 {
    @include size-L;
    font-weight: 700;
    font-family: $font-stack-narrow;
    letter-spacing: 0.125em;
    text-transform: uppercase;
}

h5,
.h5,
h6,
.h6 {
    @include size-M;
    font-family: $font-stack-narrow;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.125em;

}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: inherit;
    margin-bottom: $unit * 2;
    display: block;
}

.minor{
    @include size-XS;
    font-family: $font-stack-narrow;
    color: $color-green;
    font-weight: 600;
    letter-spacing: 0.09375rem;
    text-transform: uppercase;
    text-align: right;
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    
    &:hover{
        color: $color-accent;
    }
}
