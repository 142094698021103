//HTML and Body Styling
html {
    font-size: 100%;
    font-variant-numeric: lining-nums;

    @include breakpoint(ML) {
        font-size: 87.5%;
    }
    @include breakpoint(XXXL) {
        font-size: 125%;
    }
}

body {
    line-height: $unit * 3;
    font-weight: 400;
    color: $color-body;
    font-family: $font-stack-primary;
    position: relative;
}

//Import base style partials
@import "fonts";
@import "headings";
@import "paragraphs";
@import "content-rhythm";
@import "captions";

.lh-reset {
    line-height: 1 !important;
}