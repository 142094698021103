p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: inherit;
    font-family: $font-stack-narrow;
}

em, i {
    font-style: italic !important;
}

strong {
    font-weight: 700;
}
