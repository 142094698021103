@font-face {
    font-family: 'Raleway';
    src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-500italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/wp-content/themes/lensbury/assets/fonts/raleway/raleway-v29-latin-700italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Seravek';
  src: local('Seravek');
  font-weight: 400;
  size-adjust: 106%;
}

@font-face {
    font-family: 'Didot';
    src: local('Didot');
    font-weight: 400;
    size-adjust: 85%;
  }
    

